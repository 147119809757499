<template>
  <div class="high-setting">
    <Form
      ref="formValidate"
      :model="formValidate"
      :label-width="130"
      :label-colon="true"
      :rules="ruleValidate"
    >
      <FormItem>
         <span slot="label" class="startEnd">每天投票起始:</span>
        <FormItem prop="voteStartTime" style="float: left">
        <div>
          <span>每天</span>
            <TimePicker
              type="time"
              placeholder="请选择开始时间"
              v-model="formValidate.voteStartTime"
            ></TimePicker>

          <span>之前不能投票</span>
        </div>
         </FormItem>
         <FormItem prop="voteEndTime" style="margin-left: 30px;float: left">
        <div>
          <span>每天</span>
          <TimePicker
            type="time"
            placeholder="请选择结束时间"
            v-model="formValidate.voteEndTime"
          ></TimePicker>
          <span>之后不能投票</span>
        </div>
         </FormItem>
      </FormItem>
      <FormItem label="评论" prop="openDiscuss">
        <RadioGroup v-model="formValidate.openDiscuss">
          <Radio :label="item.dictKey" v-for="(item,index) in formValidate.openDiscussList" :key="index">
            {{ item.dictValue }}
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="实名认证" prop="realNameAuth">
        <RadioGroup v-model="formValidate.realNameAuth">
          <Radio :label="item.dictKey" v-for="(item,index) in formValidate.realNameAuthList" :key="index">
            {{ item.dictValue }}
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="是否推荐" prop="recommend">
        <RadioGroup v-model="formValidate.recommend">
          <Radio label="1">是</Radio>
          <Radio label="0">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="推送方式" >
          <Checkbox  v-model="formValidate.pushChannel" >小程序订阅通知</Checkbox>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
      formData: {},
    };
  },
  methods: {
    backStep() {
      this.$emit("nextStep", -1);
    },
    save() {
      this.$refs.formValidate.validate(valid => {
        if(valid) {
           this.$emit("save");
        }
      })
    },
  },
};
</script>

<style scoped lang='less'>
.high-setting {
  width: 100%;
  .ivu-date-picker {
    margin: 0 6px;
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 20px;
    }
  }
}
.startEnd {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>
