<template>
  <div class="rule">
    <div class="vote-rule">
      <h3 class="title">投票规则</h3>
      <Form
        :model="formValidate"
        :label-colon="true"
        :label-width="140"
        :rules="ruleValidate"
        ref="formValidate"
      >
        <FormItem label="投票类型" prop="voteType">
          <RadioGroup v-model="formValidate.voteType">
            <Radio :label="item.dictKey" v-for="(item,index) in formValidate.voteTypeList" :key="index">{{item.dictValue}}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="周期设置" prop="voteCycle">
          <RadioGroup v-model="formValidate.voteCycle">
            <Radio :label="item.dictKey" v-for="(item,index) in formValidate.voteCycleList" :key="index">{{item.dictValue}}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="【单选】票数设置"
          class="number-vote"
          v-if="formValidate.voteType == '1'"
        >
          <div class="number-vote-setting">
            <span>每个用户可以投</span>
            <FormItem prop="voteMaxAccount">
              <InputNumber
                :min="1"
                :max='999999999'
                v-model="formValidate.voteMaxAccount"
              ></InputNumber>
            </FormItem>
            <span>次</span>
          </div>

          <div class="number-vote-setting">
            <span>可为同一选手投</span>
            <FormItem prop="voteMaxItem">
              <InputNumber
                :min="1"
                :max='999999999'
                v-model="formValidate.voteMaxItem"
              ></InputNumber>
            </FormItem>
            <span>次</span>
          </div>
        </FormItem>
        <FormItem
          label="【多选】票数设置"
          class="number-vote"
          v-else
        >
          <div class="number-vote-setting">
            <span>最少选择</span>
            <FormItem prop="selectMinItem">
              <InputNumber
                :min="1"
                :max='999999999'
                v-model="formValidate.selectMinItem"
              ></InputNumber>
            </FormItem>
            <span>个选项</span>
          </div>
          <div class="number-vote-setting">
            <span>最多选择</span>
            <FormItem prop="selectMaxItem">
              <InputNumber
              :max='999999999'
                :min="1"
                v-model="formValidate.selectMaxItem"
              ></InputNumber>
            </FormItem>
            <span>个选项</span>
          </div>
        </FormItem>
      </Form>
    </div>

    <div class="page-element">
      <h3 class="title">页面元素</h3>
      <Form
        :model="formValidate"
        :rules="ruleValidate"
        :label-colon="true"
        :label-width="140"
        ref="formValidate1"
      >
        <FormItem label="选手称呼" prop="voteItemName">
          <Select v-model="formValidate.voteItemName">
            <Option :value="item.dictKey" v-for="(item,index) in formValidate.voteItemNameList" :key="index">{{item.dictValue}}</Option>
          </Select>
        </FormItem>
        <FormItem label="投票按钮名称" prop="buttonName">
          <Select v-model="formValidate.buttonName">
           <Option :value="item.dictKey" v-for="(item,index) in formValidate.buttonNameList" :key="index">{{item.dictValue}}</Option>
          </Select>
        </FormItem>
        <FormItem label="票数名称" prop="buttonUnit">
          <Select v-model="formValidate.buttonUnit">
          <Option :value="item.dictKey" v-for="(item,index) in formValidate.buttonUnitList" :key="index">{{item.dictValue}}</Option>
          </Select>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {};
  },
  mounted() {
    console.log(this.formValidate);
  },
  methods: {
    backStep() {
      this.$emit("nextStep", -1);
    },
    nextStep() {
      this.$refs.formValidate.validate((valid) => {
        this.$refs.formValidate1.validate((valid1) => {
          if (valid && valid1) {
            this.$emit("nextStep", 1);
          }
        });
      });
    },
  },
};
</script>
    
<style scoped lang='less'>
.rule {
  width: 100%;
  .ivu-form {
    margin: 0 30px;
  }
  .number-vote {
    .number-vote-setting {
      display: flex;
      margin-right: 20px;
      float: left;
    }
  }
  .ivu-input-number {
    margin: 0 10px;
  }
  .page-element {
    .ivu-select {
      width: 30%;
    }
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 20px;
    }
  }
}
</style>